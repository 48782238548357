import client from "./client";

const checkAnswer = async (wallet, answer) => {
  const response = await client.post("/pumpkinswap", {wallet: wallet, answer: answer});

  return response.data.correct === true;
};

const pumpkinswap = {
  checkAnswer
};

export default pumpkinswap;
