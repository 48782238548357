import React, {useEffect, useState} from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import styled from "styled-components"
import Container from "../components/Question/Container";
import colors from "../styles/colors";
import Logo from "../components/Question/Logo";
import Button from "../components/Pumpkinswap/Button";
import ConnectWallet from "../components/Question/ConnectWallet";
import leaves from "../resources/img/bamboo/leaves.gif";
import forest from "../resources/img/background/hero-background.jpg";
import Text from "../components/Core/Text";
import pumpkinswap from "../services/lambda/pumpkinswap";
import storage from "../services/storage";
import Frame from "../components/Question/Frame";
import fonts from "../styles/fonts";
import QuestionTitle from "../components/Question/QuestionTitle";
import breakpoints from "../styles/breakpoints";
import goldFrameImage from "../resources/img/question/frame.png";

const PageBackground = styled.div`
  background: url(${forest}) no-repeat;
  background-size: cover;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 100vh;
`;

const PageContent = styled.div`
  width: 100%;
  max-width: 1040px;
  padding: 0;
  margin: auto;
  text-align: center;
  min-height: 100vh;
`;

const StyledInput = styled.input`
  width: 100%;
  max-width: 320px;
  margin: 12px auto;
  letter-spacing: 4px;
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  padding: 12px;
  color: ${colors.white};
  background: ${colors.black};
  border: 1px solid ${colors.black};
  border-radius: 4px;
  outline: none;
  text-align: center;
  ${fonts.gloryBold};
  text-transform: uppercase;
  
  
  @media (min-width: ${breakpoints.lg}) {
    max-width: 400px;
    letter-spacing: 8px;
    font-size: 24px;
    height: 50px;
    line-height: 50px;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
  position: relative;
  min-height: 400px;
`;

const HeaderContainer = styled.div`
  margin: 32px auto 12px auto;
  text-align: center;
  display: flex;
  align-item: center;
  justify-content: center;
  position: relative;
`;

const ButtonContainer = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 12px;
  
  button {
    margin: auto;
  }
`;

const ConnectContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  width: auto;
  margin-bottom: 40px;
`;

const LeavesImage = styled.img`
  width: 200px;
  position: absolute;
  top: 40px;
  left: 50%;
  margin-left: 0;
  display: ${(props) => props.success ? "block" : "none"};
`;

const Errors = styled.p`
  ${fonts.gloryBold};
  color: ${colors.darkGreen};
  margin-top: 12px;
  display: block;
  line-height: 20px;
  min-height: 24px;
`;

const Clues = styled.ul`
  margin: 12px auto;
  font-size: 16px;
  line-height: 20px;
  max-width: 540px;
`;

const DesktopOnly = styled.div`
  display: none;
  
  @media (min-width: ${breakpoints.sm}) {
    display: flex;
    flex-direction: column;
  }
`;

const MobileOnly = styled.div`
  display: flex;
  
  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }
`;

const Congrats = styled.div`
  ${fonts.gloryBold};
  font-size: 42px;
  line-height: 52px;
  margin-bottom: 8px;
`;

const GoldFrame = styled.div`
  background: url(${goldFrameImage}) no-repeat;
  width: 240px;
  height: 245px;
  background-size: 100% auto;
  margin: 24px auto 0 auto;
`;

const PandaPassPage = ({location}) => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const accountData = storage.getAccount();

    if (accountData && typeof accountData !== "undefined") {
      setWalletAddress(accountData);
    }
  }, []);

  const onConnect = (address) => {
    setWalletAddress(address);
    setHasErrors(false);
  };

  const submitAnswer = async () => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    if (!walletAddress) {
      setHasErrors(true);
      setIsProcessing(false);

      return;
    }

    if (!answer || answer.length !== 8) {
      setIsSuccess(false);
      setIsProcessing(false);
      setHasErrors(true);

      return;
    }

    let isCorrect;

    try {
      isCorrect = await pumpkinswap.checkAnswer(walletAddress, answer);
    } catch (e) {
      setIsProcessing(false);
      setHasErrors(false);

      return;
    }

    setIsSuccess(isCorrect);

    setHasErrors(isCorrect !== true);

    window.setTimeout(function () {
      setHasErrors(false);
    }, 1600);

    setIsProcessing(false);
  };

  return (
    <Layout footer={"false"}>
      <Seo title={"Panda Pass #2"}
           description={"Panda Pass Pumpkin Swap"}
           path={location.pathname}/>
      <PageBackground>
        <PageContent>
          <Container>
            <HeaderContainer>
              <Logo/>
              <DesktopOnly>
                <ConnectContainer>
                  <ConnectWallet callback={onConnect}/>
                </ConnectContainer>
              </DesktopOnly>
            </HeaderContainer>
            <Frame>
              <QuestionContainer>
                {isSuccess ?
                  <DesktopOnly>
                    <Congrats>Congrats!</Congrats>
                    <QuestionTitle>Pumpkin swap unlocked.</QuestionTitle>
                    <Text>Your pumpkin will be updated shortly to match the correct color! Don't forget to refresh the metadata on OpenSea.</Text>
                  </DesktopOnly>
                  :
                  <DesktopOnly>
                    <p>And the winning Magic Pumpkin colors are: Blue, Gold, Black, Leopard, Rainbow, Pink, Ginger and Brown.
                      Transform your pumpkin by entering the secret word using the clues below for each letter:</p>
                    <StyledInput maxLength={8} type={"text"} name={"answer"}
                                 onChange={(e) => setAnswer(e.target.value)}/>
                    <ButtonContainer>
                      <Button onClick={submitAnswer} processing={isProcessing}>
                        Send
                      </Button>
                    </ButtonContainer>
                    <Errors>{hasErrors && <>{walletAddress ? "Wrong answer! Try again" : "Please connect your wallet!"}</>}</Errors>
                    <Clues>
                      <li>First letter here: <a href={"https://bit.ly/3z1ZLKX"} target={"_blank"}>https://bit.ly/3z1ZLKX</a></li>
                      <li>Second letter here: <a href={"https://bit.ly/3suaH2J"} target={"_blank"}>https://bit.ly/3suaH2J</a></li>
                      <li>Third letter here: <a href={"https://bit.ly/3erg0rj"} target={"_blank"}>https://bit.ly/3erg0rj</a></li>
                      <li>Fourth letter here: <a href={"https://bit.ly/3eiF0kD"} target={"_blank"}>https://bit.ly/3eiF0kD</a></li>
                      <li>Fifth letter here: <a href={"https://bit.ly/3enT0K1"} target={"_blank"}>https://bit.ly/3enT0K1</a></li>
                      <li>Sixth letter here: <a href={"https://bit.ly/3qnRjSs"} target={"_blank"}>https://bit.ly/3qnRjSs</a></li>
                      <li>Seventh letter here: <a href={"https://bit.ly/3EjD9qt"} target={"_blank"}>https://bit.ly/3EjD9qt</a></li>
                      <li>Eighth letter here: <a href={"https://bit.ly/30VkDad"} target={"_blank"}>https://bit.ly/30VkDad</a></li>
                    </Clues>
                  </DesktopOnly>
                }
                <MobileOnly>
                  <QuestionTitle>Visit this page on a laptop or desktop screen to get started!</QuestionTitle>
                </MobileOnly>
                <DesktopOnly>
                  <LeavesImage src={leaves} alt={"Leaves"} success={isSuccess}/>
                </DesktopOnly>
              </QuestionContainer>
            </Frame>
          </Container>
        </PageContent>
      </PageBackground>
    </Layout>
  )
};

export default PandaPassPage;
